import React from 'react';
import Helmet from 'react-helmet';
import {graphql} from 'gatsby';
import Layout from '../components/layouts/Default';
import Post from '../components/Post';
import WhyDescription from '../components/organisms/WhyDescription';
import WhatDescription from '../components/organisms/WhatDescription';
import HowDescription from '../components/organisms/HowDescription';

const PAGES = {
  why: <WhyDescription />,
  how: <HowDescription />,
  what: <WhatDescription />,
};

class ArchiveTemplate extends React.PureComponent {
  render() {
    const { edges: latest } = this.props.data.latest;
    const {
      posts,
      pageType,
      title,
      archiveLink,
      archiveLinkLabel,
      layout,
    } = this.props.pageContext;

    return (
      <Layout layout={layout}>
        <Helmet title={title} />
        <div className="archive">
          {PAGES[pageType]}
          <section className="section articles">
            <div className="section-title">
              <h2 className="center-align">おすすめ記事</h2>
            </div>
            <div className="row">
              {posts.map(({node}) => (
                <Post key={node.id} post={node} />
              ))}
            </div>
            <div className="more-link">
              <a className="link" href={archiveLink}>
                >> {archiveLinkLabel}
              </a>
            </div>
          </section>
          <section className="section articles">
            <div className="section-title">
              <h2>最新記事</h2>
            </div>
            <div className="row">
              {latest.map(({node}) => (
                <Post key={node.id} post={node} />
              ))}
            </div>
            <div className="more-link">
              <a className="link" href={archiveLink}>
                >> {archiveLinkLabel}
              </a>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default ArchiveTemplate;

export const pageQuery = graphql`
  query ArchiveQuery($category: [String]) {
    latest: allMarkdownRemark(
      filter: {frontmatter: {templateKey: {eq: "blog-post"}, categories: {in: $category}}}
      sort: {order: DESC, fields: [frontmatter___createdAt]}
      limit: 12
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            slug
            thumbnail
            templateKey
            categories
            createdAt(formatString: "MMM DD, YYYY")
            updatedAt(formatString: "MMM DD, YYYY")
          }
        }
      }
    }
  }
`;
