import React from 'react';

const WhatDescription = () => (
  <section className="section first-view what">
    <div className="first-view-title">
      <h1 className="title">Webエンジニアになるために身に付けるもの</h1>
    </div>
    <div className="section-body first-view-body">
      <p>
        Webエンジニアになるためには大きく分けて<strong>6種類</strong>の知識を
        習得する必要があります。
      </p>
      <p>
        Webエンジニアになる入り口としてエンジニアとして就転職するという目標
        があると思いますが、企業に入ってエンジニアとして働き始めるには
        独学でもスクールに通うのでも勉強方法は問いませんが
      </p>
      <p>
        一つなんらかのアプリケーションを作れるレベルに達している
        必要があります。
      </p>
      <p>
        このひとつのアプリケーションを作るのに必要な知識というのは、
        Webアプリケーションの仕組みなどITの基礎的な知識と大きくわけて6種類あります。
      </p>
      <ul className="list">
        <li className="list-item">HTML/CSS</li>
        <li className="list-item">JavaScriptでのフロントエンド開発</li>
        <li className="list-item">Ruby or PHPのサーバサイドの言語</li>
        <li className="list-item">コマンドライン操作およびOSに関する知識</li>
        <li className="list-item">データベースに関する知識</li>
        <li className="list-item">Gitに関する知識</li>
      </ul>
      <p>
        ここではこれらそれぞれの技術をWebエンジニアとして身に付けるべき技術としてひとつずつ分解して解説していきます。
      </p>
    </div>
  </section>
);

export default WhatDescription;
