import React from 'react';
const WhyDescription = () => (
  <section className="section first-view">
    <div className="first-view-title">
      <h1 className="title">なぜWebエンジニアになるのか？</h1>
    </div>
    <div className="section-body first-view-body">
      <p className="font-strong">「なんでWebエンジニアになるの？」</p>
      <p className="font-strong">「Webエンジニアになって何がしたいの？」</p>
      <br/>
      <p>
        友人にエンジニアになること、
        プログラミングを勉強している時あるいはエンジニア職の面接などなど、なんでエンジニアになろうとしたのか
        聞かれる機会はこれから沢山あるでしょう。
      </p>
      <p>
        ここでは、そんな時にWebエンジニアになるとできること、メリットはたまた大変なところなど
        に関する情報を発信することでエンジニアを目指す理由、目指すべきエンジニア像をクリアにすることをサポートします。
      </p>
    </div>
  </section>
);

export default WhyDescription;
