import React from 'react';
const HowDescription = () => (
  <section className="section first-view">
    <div className="first-view-title">
      <h1 className="title">Webエンジニアになるための方法</h1>
      <div className="first-view-body">
        <p>Webエンジニアになるためには、</p>
        <ul className="list">
          <li className="list-item">どういった技術を学ぶ必要があるのか？</li>
          <li className="list-item">どういった勉強法が有効なのか？</li>
          <li className="list-item">どういう順序で転職活動すべきか？</li>
        </ul>
        <p>とういうことを学ぶ必要があります。</p>
        <p>
          このアーカイブでは、以上三点にポイントを絞って記事を揃えています。
        </p>
        <p>
          いざWebエンジニアになろうと思ってもどうしたらよいかわからない？どういう
          順序で進めればわからないという方はこちらの記事を参考にしてみてください。
        </p>
      </div>
    </div>
    <div className="section-body first-view-body" />
  </section>
);

export default HowDescription;
